import { useState } from "react";
import { Link } from "react-router-dom";
const host = process.env.REACT_APP_SERVER;

const CreateGoogleSheetButton = ({ projectId, active }) => {
  const [loading, setLoading] = useState(false);
  const [sheetUrl, setSheetUrl] = useState("");
  const [likedNames, setLikedNames] = useState([]);

  const fetchLikedNames = async () => {
    try {
      const response = await fetch(`${host}/api/projects/${projectId}/get-liked-names`);
      if (response.ok) {
        const data = await response.json();
        return data.likedNames;
      } else {
        console.error("Failed to fetch liked names");
      }
    } catch (error) {
      console.error("Error fetching liked names:", error);
    }
  };

  const handleCreateSheet = async () => {
    setLoading(true);

    try {
      // Fetch liked names when the button is pressed
      let likedNames = await fetchLikedNames();
      // Format the data for Google Sheets
      const values = [
        [
          "Name",
          "TM",
          "Jack",
          "Mark",
          "Rationale",
          "Comments",
          "Google check",
          "Registered conflict",
          "Similarity",
          "Country",
          "Classes",
        ],
      ];

      likedNames.forEach(name => {
        values.push([name.name, name.trademark || "TBD", "TBD", "TBD", "", "", "TBD", "", "", "", ""]);

        let conflicts = name.conflicts;
        console.log("conflicts", conflicts);
        if (Array.isArray(conflicts) && conflicts.length > 0) {
          conflicts.slice(0, 20).forEach(c => {
            // Push values for each conflict object
            values.push([
              "", // Placeholder for first column
              "", // Placeholder for second column
              "", // Placeholder for third column
              "", // Placeholder for fourth column
              "", // Placeholder for fifth column
              "", // Placeholder for sixth column
              "", // Placeholder for seventh column
              c.name ? c.name : "", // Name if available, otherwise empty string
              c.similarity_to_query.toFixed(2), // Formatted similarity value
              c.country_code, // Country code
              c.classes.join(", "), // Joined classes
            ]);
          });
        } else {
          console.log("No conflicts found."); // Optional: Handle case when conflicts array is empty
        }

        values.push([""]);
      });

      console.log("values", values);
      let emails = ["chrisdaly1988@gmail.com", "chris@satori-ai.com"];
      const response = await fetch(`${host}/api/create-google-sheet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values, emails }), // Send formatted values and email in the request body
      });

      if (response.ok) {
        const result = await response.json();
        setSheetUrl(result.sheetUrl); // Assuming the backend returns the URL of the created sheet
      } else {
        console.error("Failed to create Google Sheet");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button className="btn btn-secondary text-neutral w-full" onClick={handleCreateSheet} disabled={!active}>
        {loading ? "Creating..." : "Create Google Sheet"}
      </button>
      {sheetUrl && (
        <div className="">
          <Link className="text-secondary" to={sheetUrl} target="_blank" rel="noopener noreferrer">
            <a className="pl-2">Google Sheet created!</a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CreateGoogleSheetButton;
