import {
  faBuilding,
  faCalendarAlt,
  faCheck,
  faCircle,
  faCircleDot,
  faFolder,
  faGlobe,
  faHome,
  faSort,
  faSpinner,
  faTag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const host = process.env.REACT_APP_SERVER;

  const getStatusIcon = status => {
    // Handle null/undefined status
    if (!status) return <FontAwesomeIcon icon={faCircle} className="text-base-content/20" />;

    // Convert to lowercase for consistent comparison
    const normalizedStatus = status.toString().toLowerCase().trim();

    switch (normalizedStatus) {
      case "completed":
      case "complete":
        return <FontAwesomeIcon icon={faCheck} className="text-success" />;
      case "generate":
      case "generating":
      case "mutate":
      case "mutating":
        return <FontAwesomeIcon icon={faSpinner} className="text-secondary animate-spin" />;
      case "incomplete":
      case "failed":
        return <FontAwesomeIcon icon={faTimes} className="text-error" />;
      case "created":
      case "new":
        return <FontAwesomeIcon icon={faCircleDot} className="text-info" />;
      default:
        // Return an empty circle for unknown status
        return <FontAwesomeIcon icon={faCircle} className="text-base-content/20" />;
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${host}/api/projects`);
      const jsonData = await response.json();
      jsonData.data.projects.sort((a, b) => b.id - a.id);
      setData(jsonData.data.projects);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSort = column => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    setIsFilterMenuOpen(false);
  };

  const compareValues = (valueA, valueB) => {
    if (valueA === null) return sortDirection === "asc" ? 1 : -1;
    if (valueB === null) return sortDirection === "asc" ? -1 : 1;

    if (typeof valueA === "string" && typeof valueB === "string") {
      const trademarkOrder = ["A", "B", "C", "D", "F"];
      const indexA = trademarkOrder.indexOf(valueA);
      const indexB = trademarkOrder.indexOf(valueB);

      if (indexA !== -1 && indexB !== -1) {
        return sortDirection === "asc" ? indexA - indexB : indexB - indexA;
      }
      return sortDirection === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }
    return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      return compareValues(valueA, valueB);
    }
    return 0;
  });

  const ProjectCard = ({ project }) => {
    const formatDate = dateString => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

    return (
      <div className="bg-base-200 rounded-lg p-4 mb-4 shadow-md hover:shadow-lg transition-all">
        <Link to={`/projects/${project.id}`} className="block">
          {/* Title row with vertically centered status icon */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2 flex-1">
              <div className="flex items-center justify-center opacity-70 w-4">{getStatusIcon(project.status)}</div>
              <h3 className="text-xl font-bold text-secondary leading-none">{project.name}</h3>
            </div>
            <span className="text-sm text-base-content/60 ml-2">#{project.id}</span>
          </div>

          {/* Rest of the card content remains the same */}
          <div className="grid grid-cols-2 gap-y-2 text-sm mb-4">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faBuilding} className="text-base-content/60 w-4" />
              <span className="truncate" title={project.client}>
                {project.client || "No Client"}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faTag} className="text-base-content/60 w-4" />
              <span className="truncate" title={project.category}>
                {project.category || "No Category"}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="text-base-content/60 w-4" />
              <span>{formatDate(project.timestamp)}</span>
            </div>
            {project.countries && project.countries.length > 0 && (
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faGlobe} className="text-base-content/60 w-4" />
                <span>{project.countries.join(", ")}</span>
              </div>
            )}
          </div>

          <div className="flex items-center gap-4 text-sm text-base-content/80">
            <span>{project.names_count} names generated</span>
            <span>{project.bookmarked_percent}% liked</span>
          </div>

          {project.attributes && project.attributes.length > 0 && (
            <div className="flex flex-wrap gap-2 mt-3">
              {project.attributes.map((attr, index) => (
                <span key={index} className="px-2 py-1 rounded-full bg-base-300 text-xs">
                  {attr}
                </span>
              ))}
            </div>
          )}
        </Link>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <span className="loading loading-ring loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="text-sm breadcrumbs mb-6">
        <ul className="flex flex-wrap">
          <li className="mr-2">
            <Link className="text-secondary flex items-center" to="/">
              <FontAwesomeIcon icon={faHome} className="mr-1" />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link className="text-secondary flex items-center" to="/projects">
              <FontAwesomeIcon icon={faFolder} className="mr-1" />
              <span>Projects</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex justify-center mb-6">
        <Link to="/form" className="btn btn-secondary text-neutral w-full md:w-auto">
          CREATE NEW PROJECT
        </Link>
      </div>

      <div className="mb-4">
        <button
          className="btn btn-ghost btn-sm flex items-center gap-2"
          onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
        >
          <FontAwesomeIcon icon={faSort} />
          <span>Sort</span>
        </button>

        {isFilterMenuOpen && (
          <div className="mt-2 p-4 bg-base-200 rounded-lg shadow-lg">
            <div className="grid grid-cols-2 gap-2">
              {["name", "status", "names_count", "bookmarked_percent"].map(column => (
                <button
                  key={column}
                  onClick={() => handleSort(column)}
                  className={`btn btn-sm ${sortColumn === column ? "btn-secondary" : "btn-ghost"}`}
                >
                  {column.replace("_", " ").toUpperCase()}
                  {sortColumn === column && <span className="ml-1">{sortDirection === "asc" ? "↑" : "↓"}</span>}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="space-y-4">
        {sortedData.length > 0 ? (
          sortedData.map(project => <ProjectCard key={project.id} project={project} />)
        ) : (
          <div className="text-center py-8">
            <span className="loading loading-ring loading-xs"></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
