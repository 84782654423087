function ProjectHeader({ project, status }) {
  return (
    <article className="prose">
      <div className="flex items-center">
        <h1 className="text-4xl font-bold mb-0 mr-2 text-gray-800">
          {project.name}
          {["generate", "mutate"].includes(status) && <div className="pulse-icon inline-block ml-2"></div>}
        </h1>
      </div>
    </article>
  );
}

export default ProjectHeader;
