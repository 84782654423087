import { faFolder, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ projectId }) => {
  return (
    <div className="text-sm breadcrumbs pb-5">
      <ul>
        <li>
          <Link className="text-secondary" to="/">
            <FontAwesomeIcon icon={faHome} size="1x" />
            <p className="pl-2 text-secondary">Home</p>
          </Link>
        </li>
        <li>
          <Link className="text-secondary" to="/projects">
            <FontAwesomeIcon icon={faFolder} size="1x" />
            <p className="pl-2 text-secondary">Projects</p>
          </Link>
        </li>
        <li>
          <Link className="text-secondary" to={`/projects/${projectId}`}>
            <p className="pl-2 text-secondary">{projectId}</p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BreadCrumbs;
