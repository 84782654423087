function NameFilters({ filters, onFilterChange }) {
  return (
    <div className="flex justify-center">
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Disregard 'Sensitive' Names</span>
          <input
            type="checkbox"
            className="toggle"
            checked={filters.disregardSensitive}
            onChange={e => onFilterChange("disregardSensitive", e.target.checked)}
          />
        </label>
      </div>
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Disregard D & F TM's</span>
          <input
            type="checkbox"
            className="toggle"
            checked={filters.disregardF}
            onChange={e => onFilterChange("disregardF", e.target.checked)}
          />
        </label>
      </div>
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Maximum Character Length</span>
          <input
            type="checkbox"
            className="toggle"
            checked={filters.maxCharacter}
            onChange={e => onFilterChange("maxCharacter", e.target.checked)}
          />
        </label>
      </div>
    </div>
  );
}

export default NameFilters;
