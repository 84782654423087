import { classesOptions, countryOptions } from "../../variables/countryOptions"; // Update the path to your file

const getCountryLabel = code => {
  // First, search for the exact country match
  const exactMatch = countryOptions.find(option => typeof option.value === "string" && option.value === code);

  // If no exact match, search in grouped values
  if (!exactMatch) {
    const groupMatch = countryOptions.find(option => Array.isArray(option.value) && option.value.includes(code));
    return groupMatch?.label || code;
  }

  return exactMatch.label;
};

const getClassLabel = className => {
  console.log("className", className);
  const match = classesOptions.find(option => option.value === String(className));
  console.log("MATCH", match);
  return match ? match.label : className;
};

function ProjectDetails({ project }) {
  return (
    <div className="mt-6">
      <DetailField label="Client" value={project.client} />
      <DetailField label="Business Category" value={project.category} />
      <DetailField label="Product Description" value={project.description} />
      <DetailField label="Brand Strategy" value={project.brand_strategy} />

      <AttributesList label="Personality Attributes" items={project.attributes} />
      <AttributesList label="Differentiators" items={project.differentiators} />

      <DetailField label="Maximum Characters" value={project.max_chars} />
      <DetailField label="Special Characters" value={project.special_chars} />
      <DetailField label="Local Spelling" value={project.local_spelling} />

      {/* Countries */}
      <p className="mb-4 text-gray-600 flex flex-wrap">
        <span className="font-bold">Countries: </span>
        {project.countries?.map((country, index) => (
          <span key={index} className="ml-1 mr-1 relative group cursor-pointer">
            {country}
            <span className="absolute bottom-full left-0 mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 w-auto min-w-[150px] max-w-[300px] text-left">
              {" "}
              {getCountryLabel(country)}
            </span>
          </span>
        ))}
      </p>

      {/* Nice Classes */}
      <p className="mb-4 text-gray-600 flex flex-wrap">
        <span className="font-bold">Nice Classes: </span>
        {project.nice_classes?.map(className => (
          <span key={className} className="badge badge-primary ml-2 text-neutral relative group cursor-pointer">
            {className}
            <span className="absolute bottom-full left-0 mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 w-auto min-w-[150px] max-w-[300px] text-left">
              {" "}
              {getClassLabel(className)}
            </span>
          </span>
        ))}
      </p>

      <DetailField label="Created On" value={new Date(project.timestamp).toLocaleDateString()} />
      <DetailField label="Status" value={project.status} />
      <DetailField label="Test Project" value={String(project.test_project)} />
    </div>
  );
}

// Helper components
const DetailField = ({ label, value }) => (
  <p className="mb-4 text-gray-600">
    <span className="bold">{label}:</span> {value}
  </p>
);

const AttributesList = ({ label, items = [] }) => (
  <p className="mb-4 text-gray-600">
    <span className="bold">{label}: </span>
    {items?.map(item => (
      <span className="badge badge-primary ml-1 mr-1 text-neutral" key={item}>
        {item.toLowerCase()}
      </span>
    ))}
  </p>
);

export default ProjectDetails;
