import { AlertTriangle, Heart } from "lucide-react";

const NamesTable = ({ names, sortColumn, sortDirection, onSort, onBookmark, projectId, maxChars = 0 }) => {
  const renderSortIndicator = column => {
    if (sortColumn === column) {
      return sortDirection === "asc" ? "↑" : "↓";
    }
    return null;
  };

  const getProcess = name => {
    // Return the latest mutation if it exists, otherwise return the model
    return name.mutations?.[-1] || name.process || name.model || "-";
  };

  return (
    <div className="bg-base-200 rounded-lg">
      <div className="max-h-[calc(100vh-8rem)] overflow-y-auto">
        <table className="table table-xs">
          <thead className="sticky top-0 z-10">
            <tr>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("bookmarked")}
              >
                Liked {renderSortIndicator("bookmarked")}
              </th>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("name")}
              >
                Name {renderSortIndicator("name")}
              </th>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("overall_grade")}
              >
                GRADE {renderSortIndicator("overall_grade")}
              </th>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("trademark")}
              >
                Trademark {renderSortIndicator("trademark")}
              </th>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("trademark_effective")}
              >
                Trademark Effective {renderSortIndicator("trademark_effective")}
              </th>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("sensitivity")}
              >
                Sensitivity {renderSortIndicator("sensitivity")}
              </th>
              <th
                className="bg-neutral text-neutral-content cursor-pointer hover:bg-neutral-focus"
                onClick={() => onSort("process")}
              >
                Process {renderSortIndicator("process")}
              </th>
            </tr>
          </thead>
          <tbody>
            {names.map(name => (
              <tr
                key={name.id}
                className={`${
                  name.name.length > maxChars ? "bg-red-100 bg-opacity-20" : ""
                } hover:bg-base-300 transition-colors`}
              >
                <td>
                  <button
                    onClick={() => onBookmark(name)}
                    className="focus:outline-none focus:ring-2 focus:ring-secondary rounded-full p-1"
                    aria-label={name.bookmarked ? "Remove from favorites" : "Add to favorites"}
                  >
                    <Heart
                      className={`h-5 w-5 transition-colors ${
                        name.bookmarked ? "fill-secondary stroke-secondary" : "stroke-gray-400"
                      }`}
                    />
                  </button>
                </td>
                <td>
                  <a
                    href={`/projects/${projectId}/names/${name.id}`}
                    className="text-secondary hover:underline flex items-center gap-2"
                  >
                    {name.name}
                    {name.sensitivity && (
                      <AlertTriangle className="h-4 w-4 text-secondary" aria-label="Sensitivity warning" />
                    )}
                  </a>
                </td>
                <td>{name.overall_grade || "-"}</td>
                <td>{name.trademark || "-"}</td>
                <td>{name.trademark_effective || "-"}</td>
                <td>
                  {name.sensitivity ? (
                    <AlertTriangle className="h-4 w-4 text-secondary" aria-label="Sensitive content" />
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  <a href={`/processes/${getProcess(name)}`} className="text-secondary hover:underline">
                    {getProcess(name)}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NamesTable;
