import {
  faChartGantt,
  faExclamationCircle,
  faFilter,
  faRefresh,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreateGoogleSheetButton from "../../components/CreateGoogleSheetButton";
// Components imports
import BreadCrumbs from "./BreadCrumbs";
import NameFilters from "./NameFilters";
import NamesTable from "./NamesTable";
import ProjectDetails from "./ProjectDetails";
import ProjectHeader from "./ProjectHeader";

const updateProgressFromStatus = status => {
  const progressStates = {
    complete: { create: true, generate: true, mutate: true, complete: true },
    generate: { create: true, generate: true, mutate: false, complete: false },
    mutate: { create: true, generate: true, mutate: true, complete: false },
    create: { create: true, generate: false, mutate: false, complete: false },
  };

  return progressStates[status] || { create: true, generate: false, mutate: false, complete: false };
};

const Project = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const host = process.env.REACT_APP_SERVER;

  // State management
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [nameData, setNameData] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [filters, setFilters] = useState({
    disregardF: true,
    disregardSensitive: true,
    maxCharacter: true,
    searchTerm: "",
  });
  const [generationStatus, setGenerationStatus] = useState({
    isGenerating: false,
    progress: { create: true, generate: false, mutate: false, complete: false },
    status: null,
  });
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  // Handlers
  const handleDelete = async () => {
    const confirmDelete = window.confirm(`Are you sure you want to permanently DELETE this project?`);
    if (confirmDelete) {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          navigate("/projects");
        } else {
          throw new Error("Failed to delete project");
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleClear = async () => {
    const confirmClear = window.confirm("Are you sure you want to CLEAR all the names from this project?");
    if (confirmClear) {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}/clear`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          window.location.reload();
        } else {
          throw new Error("Failed to clear names");
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  // Save scroll position
  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem("projectScrollPosition", window.scrollY.toString());
    };

    const savedPosition = sessionStorage.getItem("projectScrollPosition");
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition));
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Query parameters
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      modelType: params.get("model"),
      mutationType: params.get("mutation"),
    };
  }, [location.search]);

  // Sort names
  const sortedNames = useMemo(() => {
    if (!sortColumn) return nameData;

    return [...nameData].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (sortColumn === "bookmarked") {
        const comparison = a.bookmarked === b.bookmarked ? 0 : a.bookmarked ? -1 : 1;
        return sortDirection === "asc" ? comparison : -comparison;
      }

      if (!aValue && !bValue) return 0;
      if (!aValue) return sortDirection === "asc" ? 1 : -1;
      if (!bValue) return sortDirection === "asc" ? -1 : 1;

      const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      return sortDirection === "asc" ? comparison : -comparison;
    });
  }, [nameData, sortColumn, sortDirection]);

  // Apply filters
  const filteredNames = useMemo(() => {
    return sortedNames.filter(name => {
      if (filters.disregardF && ["D", "F"].includes(name.trademark)) return false;
      if (filters.disregardSensitive && name.sensitivity) return false;
      if (filters.maxCharacter && projectData?.max_chars && name.name.length > projectData.max_chars) return false;
      if (filters.searchTerm && !name.name.toLowerCase().includes(filters.searchTerm.toLowerCase())) return false;
      return true;
    });
  }, [sortedNames, filters, projectData?.max_chars]);

  // Fetch project data
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(`${host}/api/projects/${projectId}`);
        if (!response.ok) throw new Error("Failed to fetch project data");

        const { data } = await response.json();
        setProjectData(data.project);
        setGenerationStatus(prev => ({
          ...prev,
          status: data.project.status,
          progress: updateProgressFromStatus(data.project.status),
        }));
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProjectData();
  }, [projectId, host]);

  // Fetch name data
  useEffect(() => {
    const fetchNameData = async () => {
      if (!projectData) return;

      try {
        const maxCharsQuery = projectData.max_chars ? `?max_chars=${projectData.max_chars}` : "";
        const response = await fetch(`${host}/api/projects/${projectId}/names${maxCharsQuery}`);
        if (!response.ok) throw new Error("Failed to fetch name data");

        const { data } = await response.json();
        let filteredNames = data.names;

        if (queryParams.modelType) {
          filteredNames = filteredNames.filter(name => name.model === queryParams.modelType);
        }
        if (queryParams.mutationType) {
          filteredNames = filteredNames.filter(name => name.mutations?.[-1] === queryParams.mutationType);
        }

        setNameData(filteredNames);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    if (projectData) {
      fetchNameData();
    }
  }, [projectId, host, projectData, queryParams.modelType, queryParams.mutationType]);

  const handleSort = column => {
    setSortColumn(prevColumn => {
      if (prevColumn === column) {
        setSortDirection(prev => (prev === "asc" ? "desc" : "asc"));
      } else {
        setSortDirection("asc");
      }
      return column;
    });
  };

  const handleGenerateNames = async () => {
    setGenerationStatus(prev => ({ ...prev, isGenerating: true }));
    let eventSource = null;

    try {
      const response = await fetch(`${host}/api/projects/${projectId}/generate_names`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) throw new Error("Failed to initiate name generation");

      eventSource = new EventSource(`${host}/api/projects/${projectId}/status`);

      eventSource.onmessage = event => {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "stageUpdate":
            setGenerationStatus(prev => ({
              ...prev,
              progress: { ...prev.progress, [data.stage]: true },
              status: data.stage,
            }));
            break;
          case "nameUpdate":
            setNameData(prev => [...prev, data.nameData]);
            break;
          default:
            console.warn("Unknown update type:", data.type);
        }
      };

      eventSource.onerror = () => {
        setError("Name generation connection lost");
        eventSource.close();
      };
    } catch (error) {
      setError(error.message);
      setGenerationStatus(prev => ({ ...prev, isGenerating: false }));
      if (eventSource) eventSource.close();
    }
  };

  const handleBookmarkToggle = async name => {
    setNameData(prev => prev.map(n => (n.id === name.id ? { ...n, bookmarked: !n.bookmarked } : n)));

    try {
      const response = await fetch(`${host}/api/projects/${projectId}/names/${name.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ bookmarked: !name.bookmarked }),
      });

      if (!response.ok) throw new Error("Failed to update bookmark");
    } catch (error) {
      setNameData(prev => prev.map(n => (n.id === name.id ? { ...n, bookmarked: !n.bookmarked } : n)));
      setError(error.message);
    }
  };

  const handleAddName = async nameInput => {
    try {
      const response = await fetch(`${host}/api/projects/${projectId}/add_name`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: nameInput,
          countries: projectData.countries,
          nice_classes: projectData.nice_classes,
        }),
      });

      if (!response.ok) throw new Error("Failed to add name");

      const result = await response.json();
      navigate(`/projects/${projectId}/names/${result.data.nameId}`);
    } catch (error) {
      setError(error.message);
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto p-4">
        <div className="flex justify-center items-center h-64">
          <div className="loading loading-ring loading-lg"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <div className="alert alert-error">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <BreadCrumbs projectId={projectId} />

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Left Column */}
        <div className="w-full lg:w-1/3">
          <div className="sticky top-4 space-y-6">
            <div className="bg-base-200 rounded-lg p-8 shadow-sm">
              <div className="space-y-6">
                <ProjectHeader project={projectData} status={generationStatus.status} />
                <div className="divider my-6" />
                <ProjectDetails project={projectData} />
                <div className="divider my-6" />

                {/* Action Buttons */}
                <div className="flex justify-center gap-8">
                  <button
                    className="tooltip tooltip-bottom"
                    data-tip="View Stats"
                    onClick={() => navigate(`/projects/${projectId}/stats`)}
                  >
                    <FontAwesomeIcon icon={faChartGantt} size="xl" className="text-secondary hover:opacity-80" />
                  </button>
                  <button className="tooltip tooltip-bottom" data-tip="Delete Project" onClick={handleDelete}>
                    <FontAwesomeIcon icon={faTrash} size="xl" className="text-secondary hover:opacity-80" />
                  </button>
                  <button className="tooltip tooltip-bottom" data-tip="Clear Names" onClick={handleClear}>
                    <FontAwesomeIcon icon={faRefresh} size="xl" className="text-white hover:opacity-80" />
                  </button>
                </div>
              </div>
            </div>

            {/* Progress Section */}
            <div className="bg-base-200 rounded-lg p-8 shadow-sm space-y-10">
              {generationStatus.status !== "complete" && (
                <ul className="steps steps-vertical lg:steps-horizontal w-full">
                  {Object.entries(generationStatus.progress).map(([stage, completed]) => (
                    <li key={stage} className={`step ${completed ? "step-primary" : ""}`}>
                      {stage.charAt(0).toUpperCase() + stage.slice(1)}
                    </li>
                  ))}
                </ul>
              )}
              <button
                className="btn btn-secondary w-full"
                onClick={handleGenerateNames}
                disabled={generationStatus.isGenerating}
              >
                {generationStatus.isGenerating ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    Generating...
                  </>
                ) : (
                  "Generate Names"
                )}
              </button>
              {/* Manual Name Addition */}
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleAddName(e.target.nameInput.value);
                  e.target.reset();
                }}
                className="space-y-4"
              >
                <input
                  type="text"
                  name="nameInput"
                  placeholder="Add name manually..."
                  className="input w-full"
                  required
                />
                <button type="submit" className="btn btn-secondary w-full">
                  Add Name
                </button>
              </form>
              {<CreateGoogleSheetButton projectId={projectId} active={nameData.some(row => row.bookmarked)} />}{" "}
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="w-full lg:w-2/3 space-y-6">
          {/* Filters */}
          <div className="bg-base-200 rounded-lg p-6 shadow-sm">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Filters</h3>
              <button
                className="btn btn-outline btn-sm lg:hidden"
                onClick={() => setIsMobileFiltersOpen(!isMobileFiltersOpen)}
              >
                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                {isMobileFiltersOpen ? "Hide Filters" : "Show Filters"}
              </button>
            </div>

            <div className={`${isMobileFiltersOpen ? "block" : "hidden lg:block"}`}>
              <NameFilters
                filters={filters}
                onFilterChange={(key, value) => setFilters(prev => ({ ...prev, [key]: value }))}
              />
            </div>
          </div>

          {/* Names Table */}
          <div className="bg-base-200 rounded-lg shadow-sm">
            <NamesTable
              names={filteredNames}
              projectId={projectId}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSort={handleSort}
              onBookmark={handleBookmarkToggle}
              filters={filters}
              maxChars={projectData?.max_chars}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
